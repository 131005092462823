import logo from './logo.svg';
import './App.css';
import React from 'react';
import GoogleMapReact from 'google-map-react';
import {Map, InfoWindow, Marker, GoogleApiWrapper} from 'google-maps-react';
import Disqus from "disqus-react"

const AnyReactComponent = ({ text }) => <div><img height='20' src='https://toppng.com/uploads/preview/map-marker-icon-600x-map-marker-11562939743ayfahlvygl.png'/>{text}</div>;
const disqusShortname = "mandiryahinbanega";
const disqusConfig = {
  url: "https://mandiryahinbanega.com",
  identifier: "production",
  title: "home"
};

export class App extends React.Component {

 constructor(props) {
    super(props);
  }

  componentDidMount() {
    
  }
  
  render(){
    return (
      <div className="App">

        <div className="main-content-wrapper">
          <div className="main-content">
            <div className="map-wrapper">

            <Map 
              className="map-content"
              google={this.props.google} 
              zoom={16}
              initialCenter={{
                lat: 26.7956,
                lng: 82.1943
              }} >
             <Marker
                  title={'Insert mandir here'}
                  name={'Mandir Yahin Banega'}
                  position={{lat: 26.7956, lng: 82.1943}}
                  icon={{
                    url: 'https://img.icons8.com/ios-filled/50/fa314a/arrow.png',
                    anchor: new this.props.google.maps.Point(70,47),
                    scaledSize: new this.props.google.maps.Size(64,64)
                  }} />
            </Map>
            </div>

              <div id="disqus_thread">
                <Disqus.DiscussionEmbed
                    shortname={disqusShortname}
                    config={disqusConfig}
                  />
              </div>
          </div>
        </div>
      </div>
    );
  }
}

export default GoogleApiWrapper({
  apiKey: 'AIzaSyCCx8RiidvDOBqhzuuHrHK4RvH-DLSnobg'
})(App);
